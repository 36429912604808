<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useUserStore } from "./stores";
import { localStorageGetItem } from "./core/localStorage/LocalStorage";
const user = useUserStore();
onMounted(() => {
  user
    .fetchingUserInfo()
    .then(() => {
      if (localStorageGetItem("selected_organization")) {
        user.fetchUserAccess();
        user.getCompanyInfo();
        user.fetchInternalUserList();
      }
      user.fetchOrganizations().then(() => {
        user.$state.shouldAuthenticationBegin = true;
      });
    })
    .catch((err) => {
      user.$state.shouldAuthenticationBegin = true;
    });
});
</script>

export enum LOCATION_EVENTS {
  "FROM" = "from",
  "FROM_VIA" = "from-via",
  "TO" = "mid-stop",
  "RETURN_FROM" = "restart-mid",
  "TO_VIA" = "to-via",
  "RETURN_TO" = "to",
}

export enum USER_STATUS {
  "ACTIVE" = "ACTIVE",
  "PENDING" = "PENDING",
}

export enum SEARCH_TYPE {
  "TEXT" = "text",
  "DATE" = "date",
  "STATUS" = "status",
  "ORGANIZATION" = "organization",
  "INVOICED_TRIP" = "invoiced_trip",
}

export const TRIP_STATUS = {
  ALL: "all",
  PLANNING: "planning",
  DRAFT: "draft",
  CANCELED: "canceled",
  READY: "ready",
  RUNNING: "running",
  COMPLETE: "complete",
  ABORTED: "aborted",
};

export const ORDER_STATUS = {
  ALL: "all",
  REQUESTED: "requested",
  REQUEST_REJECTED: "request_rejected",
  QUOTATION: "quotation",
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  ACTIVE: "active",
  CANCELED: "canceled",
  COMPLETED: "completed",
};

export enum TRIP_TYPE {
  FIXED = "FIXED",
  NORMAL = "NORMAL",
  AIRPORT = "AIRPORT",
  VIP = "VIP",
  LAYOVER = "LAYOVER",
  ONEWAY = "oneway",
  RETURN = "return",
}

export enum ROLE {
  STANDARD = "standard",
  READ_ONLY = "read-only",
  ADMIN = "admin",
  MASTER_ADMIN = "masterAdmin",
}
/**
 * [route.permission]
 */
export const MENU_PERMISSION = {
  [ROLE.ADMIN]: ["*.*"],
  [ROLE.MASTER_ADMIN]: ["*.*"],
  [ROLE.STANDARD]: [
    "orders.*",
    "active_trips.*",
    "upcoming-trips.*",
    "all-trips.*",
    "create_order.*",
    "structure.*",
  ],
  [ROLE.READ_ONLY]: ["*.read"],
};
